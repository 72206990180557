body, html {
  background: rgb(236, 236, 243) !important;
  font-family: Arial, sans-serif;
}

.app {
  max-width: 650px;
  margin: 0 auto;
}

.adm-button {
  &.adm-button-primary {
    color: #593815;
  }
  padding: 10px 12px;
}

:root:root {
  --adm-color-border: #979797;
  --adm-color-primary: #E6BE94;
  --adm-color-text: rgba(0, 0, 0, .87);
  --adm-color-light: rgba(0, 0, 0, .38);
}

.notice-wrap {
  position: sticky;
  top: 0; /* 设置吸顶的位置 */
  z-index: 999; /* 确保sticky定位的元素在最上面 */
}

.notice-button {
  position: fixed;
  right: 0;
  top: 20vh;
  z-index: 999;
}